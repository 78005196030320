<template>
  <div>
    <v-card elevation="0">
      <v-toolbar :color="$store.getters.getColor('primary')" class="" extended>
        <template v-slot:extension>
          <v-fab-transition>
            <photo-capture v-model="urlFoto" :confirmacaoDados="true" />
          </v-fab-transition>
          <h2 class="white--text ml-4">Confirmação de dados</h2>
        </template>
      </v-toolbar>
      <v-card-text :class="$vuetify.breakpoint.mdAndUp ? 'mt-7' : 'mt-12'">
        <v-row justify="center">
          <v-col class="col-12 col-md-8">
            <validation-observer ref="form" slim>
              <h3 class="mb-7">
                Dados pessoais
              </h3>
              <base-text-field label="Tipo corretor" name="Tipo corretor" :data-test-id="'tipo-corretor'" id="tipoCorretor" :disabled="true" v-model="form.tipoCorretor" clearable outlined />
              <base-text-field
                id="nome"
                name="Nome completo"
                rules="required|special_characters"
                :data-test-id="'nome-completo'"
                v-model="form.nome"
                label="Nome completo"
                :disabled="flagDisabled"
                clearable
                outlined
              />
              <base-text-field
                id="nascimento"
                name="Data de nascimento"
                rules="required|date"
                :data-test-id="'dob'"
                v-model="form.dataNascimento"
                label="Data de nascimento"
                v-mask="'##/##/####'"
                :disabled="flagDisabled"
                clearable
                outlined
              />
              <base-text-field id="cpf" name="CPF" :data-test-id="'cpf'" v-model="form.cpf" label="CPF" v-mask="'###.###.###-##'" :disabled="true" clearable outlined ref="cpf" />

              <h3 class="mb-7">
                Contato
              </h3>
              <base-text-field id="email" name="E-mail" rules="required|email" :data-test-id="'email'" v-model="form.contato.email" label="E-mail" :disabled="flagDisabled" clearable outlined />
              <base-text-field
                id="telefonePreferencial"
                name="telefonePreferencial"
                :rules="telefonePreferencial.tipo === 'Celular' ? 'required|ddd|cellphone' : 'required|ddd|phone'"
                :data-test-id="'telefone-preferencial'"
                label="Telefone preferencial"
                :disabled="flagDisabled"
                v-mask="['## #####-####', '## ####-####']"
                v-model="telefonePreferencial.numero"
                outlined
              />

              <h3 class="mb-7">
                Endereço
              </h3>
              <async-cep-residencial :cep="form.endereco.cep" v-slot="{ loading: loadingRequestCep }" @success="successCep($event)">
                <base-text-field
                  rules="required|min:9"
                  inputmode="numeric"
                  id="cep"
                  :data-test-id="'cep'"
                  name="CEP"
                  v-model="form.endereco.cep"
                  label="CEP *"
                  :disabled="flagDisabled"
                  :loading="loadingRequestCep"
                  clearable
                  outlined
                  v-mask="'#####-###'"
                />
              </async-cep-residencial>
              <base-text-field rules="required" id="uf" name="UF" :disabled="true" v-mask="'AA'" v-model="form.endereco.estado" label="UF *" outlined />
              <base-text-field :disabled="true" rules="required" id="municipio" :data-test-id="'municipio'" name="Município" v-model="form.endereco.cidade" label="Município *" outlined />
              <base-text-field rules="required" id="bairro" :data-test-id="'bairro'" name="Bairro" v-model="form.endereco.bairro" label="Bairro *" :disabled="true" clearable outlined />
              <base-text-field
                rules="required"
                id="logradouro"
                :data-test-id="'logradouro'"
                name="Logradouro"
                v-model="form.endereco.logradouro"
                label="Logradouro *"
                :disabled="true"
                clearable
                outlined
              />
              <base-text-field
                rules="required"
                v-mask="['############']"
                id="numero"
                :data-test-id="'numero'"
                name="Número"
                v-model="form.endereco.numero"
                label="Número *"
                :disabled="flagDisabled"
                clearable
                outlined
              />
              <base-text-field
                rules=""
                id="complemento"
                name="Complemento"
                :data-test-id="'complemento'"
                v-model="form.endereco.complemento"
                label="Complemento"
                :disabled="flagDisabled"
                clearable
                outlined
              />
            </validation-observer>
          </v-col>
        </v-row>
      </v-card-text>
      <v-container fill-height fluid class="pt-0 mb-3">
        <v-row align="center" justify="center">
          <v-col cols="4">
            <v-btn v-if="flagDisabled" large elevation="10" block color="warning" @click="flagDisabled = !flagDisabled">
              Editar
            </v-btn>
            <v-btn v-else large elevation="10" block color="error" @click="onClickCancelar()">
              Cancelar
            </v-btn>
          </v-col>
          <v-col cols="4">
            <v-btn v-if="flagDisabled" large elevation="10" block color="primary" @click="submit">
              Confirmar Dados
            </v-btn>
            <v-btn v-else large elevation="10" block color="primary" @click="onClickSalvar()">
              Salvar
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-dialog v-model="showModalConfirmacaoDados" persistent max-width="500">
      <v-card>
        <v-card-title>
          Confirmação de dados
        </v-card-title>
        <v-card-text v-if="quantidadeAcessos >= 0">
          Olá {{ user.nome }}, é necessário confirmar seus dados cadastrais. Caso esta confirmação não seja realizada em até {{ quantidadeAcessos }} acesso(s) ao sistema, o sistema ficará indisponível
          até que a confirmação seja realizada.
        </v-card-text>
        <v-card-text v-else> Olá {{ user.nome }}, para acessar o sistema é necessário fazer a validação do seu e-mail e telefone. </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="pt-1" color="primary" @click="showModalConfirmacaoDados = false">
            {{ quantidadeAcessos >= 0 ? "OK" : "VALIDAR" }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showModalAcessoBloqueado" max-width="500">
      <v-card>
        <v-card-title>
          Aviso
        </v-card-title>
        <v-card-text> Olá {{ user.nome }}, para acessar o sistema é necessário fazer a validação do seu e-mail e telefone. </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="pt-1" color="error" @click="logoutApp">
            CANCELAR
          </v-btn>
          <v-btn class="pt-1" color="primary" @click="onClickValidar">
            VALIDAR
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <validacao-token-celular ref="refValidacaoTokenCelular" @token-celular-valido="tokenCelularValido" />
    <validacao-token-email ref="refValidacaoTokenEmail" @salvar-dados="salvarDados" />
  </div>
</template>

<script>
import BaseTextField from "@/components/BaseTextField.vue";
import { mapState, mapActions } from "vuex";
import AsyncCepResidencial from "@/components/AsyncCepResidencial.vue";
import filters from "@/filters";
import usuarioService from "@/services/usuarioService";
import fotoPadrao from "@/assets/img/avatar.png";
import PhotoCapture from "@/components/PhotoCapture.vue";
import { cloneDeep, filter } from "lodash";
import ValidacaoTokenCelular from "./ValidacaoTokenCelular.vue";
import ValidacaoTokenEmail from "./ValidacaoTokenEmail.vue";

export default {
  components: {
    BaseTextField,
    AsyncCepResidencial,
    PhotoCapture,
    ValidacaoTokenCelular,
    ValidacaoTokenEmail,
  },
  data() {
    return {
      form: {},
      defaultForm: {},
      defaultTelefonePreferencial: {
        numero: "",
        tipo: "Celular",
        preferencial: true,
      },
      flagDisabled: true,
      urlFoto: null,
      showModalConfirmacaoDados: false,
      showModalAcessoBloqueado: false,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user.profile,
      urlFotoPerfil: (state) => state.user.urlFotoPerfil,
    }),
    telefonePreferencial() {
      if (this.form && this.form.contato && this.form.contato.telefones) {
        const telPref = this.form.contato.telefones.find((item) => item.preferencial);
        return telPref || this.defaultTelefonePreferencial;
      }
      return this.defaultTelefonePreferencial;
    },
    quantidadeAcessos() {
      let quantidadeAcessos;
      const { tentativasConfirmacaoDados } = this.user;
      if (!tentativasConfirmacaoDados) quantidadeAcessos = 3;
      else if (tentativasConfirmacaoDados === 1) quantidadeAcessos = 2;
      else if (tentativasConfirmacaoDados === 2) quantidadeAcessos = 1;
      else if (tentativasConfirmacaoDados === 3) quantidadeAcessos = 0;
      else quantidadeAcessos = -1;
      return quantidadeAcessos;
    },
  },
  created() {
    if (this.$route.query.login) this.showModalConfirmacaoDados = true;
    this.setData();
  },
  methods: {
    ...mapActions({
      logout: "auth/logout",
      setUser: "user/setUserData",
      setUrlFotoPerfil: "user/setUrlFotoPerfil",
    }),
    async logoutApp() {
      await this.logout();
      this.$router.push({ name: "login" });
    },
    async successCep(data) {
      this.form.endereco.estado = data.estado;
      this.form.endereco.cidade = data.cidade;
      this.form.endereco.bairro = data.bairro;
      this.form.endereco.logradouro = data.logradouro;
    },
    setData() {
      this.form = {
        ...this.$cleanObserver(this.user),
      };

      if (this.form && this.form.dataNascimento) {
        this.form.dataNascimento = filters.convertDateOutput(this.form.dataNascimento);
      }

      if (this.form && this.form.endereco && this.form.endereco.cep && this.form.endereco.cep.length === 8) {
        const p1 = this.form.endereco.cep.substring(0, 5);
        const p2 = this.form.endereco.cep.substring(5, 8);
        this.form.endereco.cep = `${p1}-${p2}`;
      }

      if (this.form && this.form.contato && this.form.contato.telefones) {
        this.form.contato.telefones = this.form.contato.telefones.map((item) => {
          const qntChar = item.numero.length;
          let p1;
          let p2;
          const dd = item.numero.substring(0, 2);
          if (qntChar === 11) {
            p1 = item.numero.substring(2, 7);
            p2 = item.numero.substring(7, 11);
          } else if (qntChar === 10) {
            p1 = item.numero.substring(2, 6);
            p2 = item.numero.substring(6, 10);
          }
          const concat = `${dd} ${p1}-${p2}`;
          return { ...item, numero: concat };
        });
      }
      this.urlFoto = this.urlFotoPerfil.slice(0) || fotoPadrao;
      this.defaultForm = cloneDeep(this.form);
    },
    setUserData() {
      if (this.form && this.form.dataNascimento) {
        this.form.dataNascimento = filters.convertDateInput(this.form.dataNascimento);
      }
      if (this.form && this.form.endereco && this.form.endereco.cep && this.form.endereco.cep.length === 9) {
        this.form.endereco.cep = filters.removeSymbols(this.form.endereco.cep);
      }
      if (this.form && this.form.cpf) {
        this.form.cpf = filters.removeSymbols(this.form.cpf);
      }
      if (this.form && this.form.contato && this.form.contato.telefones) {
        this.form.contato.telefones = this.form.contato.telefones.map((item) => {
          item.numero = filters.removeSymbols(item.numero);
          return item;
        });
      }
      this.setUser({ ...this.form, flagConfirmacaoDados: true });
    },
    async submit() {
      const isValid = await this.$refs.form.validate();

      if (!isValid) {
        this.$root.$snackBar.open({
          color: "error",
          message: "Verifique o preenchimento do formulário e tente novamente",
        });
        return;
      }

      const { estado } = this.user.endereco;
      if (estado !== this.form.endereco.estado) {
        this.$root.$snackBar.open({
          color: "error",
          message: "Para mudar de UF acione o apoio comercial da Qualicorp através do Whatsapp e solicite a alteração.",
        });
        return;
      }

      // Check e-mail e telefone disponíveis
      try {
        const { data: isValidEmailTelefone } = await usuarioService.checkEmailTelefoneDisponiveis({
          cpf: this.user.cpf,
          telefone: this.telefonePreferencial.numero,
          email: this.form.contato.email,
        });
        if (!isValidEmailTelefone) {
          this.$root.$snackBar.open({
            color: "error",
            message: "Campo(s) telefone e/ou e-mail já utilizados por outro corretor.",
          });
          return;
        }
      } catch (error) {
        this.$root.$snackBar.open({
          color: "error",
          message: error.message || "Erro ao validar campo(s) telefone e/ou e-mail.",
        });
      }

      if (this.quantidadeAcessos >= 0) {
        let defaultMsg = `Olá ${this.user.nome}, para seguir com o seu acesso você precisa confirmar seu e-mail e telefone.<br>Deseja fazer essa validação agora? `;
        if (this.quantidadeAcessos === 1 || this.quantidadeAcessos === 2) {
          defaultMsg += `<br><br>Atenção! Você tem mais ${this.quantidadeAcessos} acesso(s) antes do bloqueio de confirmação de dados.`;
        } else if (this.quantidadeAcessos === 0) {
          defaultMsg += `<br><br>Atenção! No próximo acesso você obrigatoriamente terá que validar seus dados para acessar o Qualivendas.`;
        }

        const resp = await this.$root.$confirmdialog.open("Aviso", defaultMsg, {
          cancelButton: "não",
          cancelButtonColor: "error",
          confirmButton: "sim",
          confirmButtonColor: "primary",
        });
        // Validação - Token Celular
        if (resp) this.$refs.refValidacaoTokenCelular.showDialog(this.telefonePreferencial.numero, this.user.cpf);
        else {
          this.$root.$snackBar.open({
            color: "error",
            message: `Você tem ${this.quantidadeAcessos} acesso(s) até que a validação seja obrigatória.`,
          });
        }
      } else this.showModalAcessoBloqueado = true;
    },
    onClickCancelar() {
      this.form = cloneDeep(this.defaultForm);
      this.flagDisabled = !this.flagDisabled;
    },
    async onClickSalvar() {
      const isValid = await this.$refs.form.validate();

      if (!isValid) {
        this.$root.$snackBar.open({
          color: "error",
          message: "Verifique o preenchimento do formulário e tente novamente",
        });
        return;
      }
      this.flagDisabled = !this.flagDisabled;
    },
    onClickValidar() {
      this.showModalAcessoBloqueado = false;
      this.$refs.refValidacaoTokenCelular.showDialog(this.telefonePreferencial.numero, this.user.cpf);
    },
    tokenCelularValido() {
      // Validação - Token Email
      this.$refs.refValidacaoTokenEmail.showDialog(this.form.contato.email, this.user.cpf);
    },
    async salvarDados() {
      try {
        // Salva dados no banco
        const telefones = filter(this.user.contato.telefones, { preferencial: false });
        const telefonePreferencial = { ...this.telefonePreferencial, numero: this.telefonePreferencial.numero.replace(/[^\d]+/g, ""), preferencial: true };
        telefones.push(telefonePreferencial);

        const formData = {
          corretor: {
            nome: this.form.nome,
            dataNascimento: this.formatDate(this.form.dataNascimento),
            telefones,
            email: this.form.contato.email,
          },
          endereco: {
            ...this.form.endereco,
            cep: this.form.endereco.cep.replace(/[^\d]+/g, ""),
          },
          confirmacaoDados: true,
        };

        await usuarioService.alterarDados(this.user.cpf, formData);

        this.setUserData();
        this.setData();

        this.$router.push({ name: "areaLogada" });

        this.$root.$snackBar.open({
          color: "success",
          message: "Dados atualizados com sucesso!",
        });
      } catch (error) {
        this.$root.$snackBar.open({
          color: "error",
          message: error.message || "Erro ao salvar dados, tente novamente mais tarde",
        });
      }
    },
    formatDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split("/");
      return `${year}-${month}-${day}`;
    },
  },
};
</script>
