var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "400px" },
          model: {
            value: _vm.dialogTokenSMS,
            callback: function ($$v) {
              _vm.dialogTokenSMS = $$v
            },
            expression: "dialogTokenSMS",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("div", {
                staticClass: "headline primary--text text-center pa-4",
                domProps: { textContent: _vm._s(`Confirme seu celular`) },
              }),
              _c("v-divider"),
              _c(
                "v-card-text",
                { staticClass: "pt-4" },
                [
                  _c("p", [
                    _vm._v(
                      " Para a validação do telefone cadastrado, insira o código/token enviado via SMS para o telefone "
                    ),
                    _c("b", [_vm._v(_vm._s(_vm.numeroCelular))]),
                    _vm._v(', e clique em "Confirmar". '),
                  ]),
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("validation-provider", {
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ errors }) {
                                  return [
                                    _c("v-text-field", {
                                      attrs: {
                                        id: "codigoconfirmacao",
                                        "error-messages": errors,
                                        "persistent-hint": "",
                                        hint: "''",
                                        label: "Código",
                                        required: "",
                                        maxlength: "4",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "message",
                                            fn: function () {
                                              return [
                                                _vm.dataContadorState === 0
                                                  ? _c(
                                                      "a",
                                                      {
                                                        staticClass:
                                                          "pa-0 text-none",
                                                        class: {
                                                          disabled:
                                                            _vm.loadingReenviarToken,
                                                        },
                                                        attrs: { small: "" },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.preventDefault()
                                                            return _vm.reenviarToken.apply(
                                                              null,
                                                              arguments
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Reenviar código"
                                                        ),
                                                      ]
                                                    )
                                                  : _c(
                                                      "a",
                                                      {
                                                        class: {
                                                          disabled:
                                                            _vm.loadingReenviarToken,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Reenviar código (" +
                                                            _vm._s(
                                                              _vm.dataContadorState
                                                            ) +
                                                            ")"
                                                        ),
                                                      ]
                                                    ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                      model: {
                                        value: _vm.codigo,
                                        callback: function ($$v) {
                                          _vm.codigo = $$v
                                        },
                                        expression: "codigo",
                                      },
                                    }),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", text: "" },
                      on: { click: _vm.cancelarToken },
                    },
                    [_vm._v("Cancelar")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        text: "",
                        loading: _vm.loadingConfirmar,
                      },
                      on: { click: _vm.confirmarToken },
                    },
                    [_vm._v("Confirmar")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }