var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-card",
        { attrs: { elevation: "0" } },
        [
          _c("v-toolbar", {
            attrs: {
              color: _vm.$store.getters.getColor("primary"),
              extended: "",
            },
            scopedSlots: _vm._u([
              {
                key: "extension",
                fn: function () {
                  return [
                    _c(
                      "v-fab-transition",
                      [
                        _c("photo-capture", {
                          attrs: { confirmacaoDados: true },
                          model: {
                            value: _vm.urlFoto,
                            callback: function ($$v) {
                              _vm.urlFoto = $$v
                            },
                            expression: "urlFoto",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("h2", { staticClass: "white--text ml-4" }, [
                      _vm._v("Confirmação de dados"),
                    ]),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _c(
            "v-card-text",
            { class: _vm.$vuetify.breakpoint.mdAndUp ? "mt-7" : "mt-12" },
            [
              _c(
                "v-row",
                { attrs: { justify: "center" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "col-12 col-md-8" },
                    [
                      _c(
                        "validation-observer",
                        { ref: "form", attrs: { slim: "" } },
                        [
                          _c("h3", { staticClass: "mb-7" }, [
                            _vm._v(" Dados pessoais "),
                          ]),
                          _c("base-text-field", {
                            attrs: {
                              label: "Tipo corretor",
                              name: "Tipo corretor",
                              "data-test-id": "tipo-corretor",
                              id: "tipoCorretor",
                              disabled: true,
                              clearable: "",
                              outlined: "",
                            },
                            model: {
                              value: _vm.form.tipoCorretor,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "tipoCorretor", $$v)
                              },
                              expression: "form.tipoCorretor",
                            },
                          }),
                          _c("base-text-field", {
                            attrs: {
                              id: "nome",
                              name: "Nome completo",
                              rules: "required|special_characters",
                              "data-test-id": "nome-completo",
                              label: "Nome completo",
                              disabled: _vm.flagDisabled,
                              clearable: "",
                              outlined: "",
                            },
                            model: {
                              value: _vm.form.nome,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "nome", $$v)
                              },
                              expression: "form.nome",
                            },
                          }),
                          _c("base-text-field", {
                            directives: [
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: "##/##/####",
                                expression: "'##/##/####'",
                              },
                            ],
                            attrs: {
                              id: "nascimento",
                              name: "Data de nascimento",
                              rules: "required|date",
                              "data-test-id": "dob",
                              label: "Data de nascimento",
                              disabled: _vm.flagDisabled,
                              clearable: "",
                              outlined: "",
                            },
                            model: {
                              value: _vm.form.dataNascimento,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "dataNascimento", $$v)
                              },
                              expression: "form.dataNascimento",
                            },
                          }),
                          _c("base-text-field", {
                            directives: [
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: "###.###.###-##",
                                expression: "'###.###.###-##'",
                              },
                            ],
                            ref: "cpf",
                            attrs: {
                              id: "cpf",
                              name: "CPF",
                              "data-test-id": "cpf",
                              label: "CPF",
                              disabled: true,
                              clearable: "",
                              outlined: "",
                            },
                            model: {
                              value: _vm.form.cpf,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "cpf", $$v)
                              },
                              expression: "form.cpf",
                            },
                          }),
                          _c("h3", { staticClass: "mb-7" }, [
                            _vm._v(" Contato "),
                          ]),
                          _c("base-text-field", {
                            attrs: {
                              id: "email",
                              name: "E-mail",
                              rules: "required|email",
                              "data-test-id": "email",
                              label: "E-mail",
                              disabled: _vm.flagDisabled,
                              clearable: "",
                              outlined: "",
                            },
                            model: {
                              value: _vm.form.contato.email,
                              callback: function ($$v) {
                                _vm.$set(_vm.form.contato, "email", $$v)
                              },
                              expression: "form.contato.email",
                            },
                          }),
                          _c("base-text-field", {
                            directives: [
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: ["## #####-####", "## ####-####"],
                                expression: "['## #####-####', '## ####-####']",
                              },
                            ],
                            attrs: {
                              id: "telefonePreferencial",
                              name: "telefonePreferencial",
                              rules:
                                _vm.telefonePreferencial.tipo === "Celular"
                                  ? "required|ddd|cellphone"
                                  : "required|ddd|phone",
                              "data-test-id": "telefone-preferencial",
                              label: "Telefone preferencial",
                              disabled: _vm.flagDisabled,
                              outlined: "",
                            },
                            model: {
                              value: _vm.telefonePreferencial.numero,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.telefonePreferencial,
                                  "numero",
                                  $$v
                                )
                              },
                              expression: "telefonePreferencial.numero",
                            },
                          }),
                          _c("h3", { staticClass: "mb-7" }, [
                            _vm._v(" Endereço "),
                          ]),
                          _c("async-cep-residencial", {
                            attrs: { cep: _vm.form.endereco.cep },
                            on: {
                              success: function ($event) {
                                return _vm.successCep($event)
                              },
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ loading: loadingRequestCep }) {
                                  return [
                                    _c("base-text-field", {
                                      directives: [
                                        {
                                          name: "mask",
                                          rawName: "v-mask",
                                          value: "#####-###",
                                          expression: "'#####-###'",
                                        },
                                      ],
                                      attrs: {
                                        rules: "required|min:9",
                                        inputmode: "numeric",
                                        id: "cep",
                                        "data-test-id": "cep",
                                        name: "CEP",
                                        label: "CEP *",
                                        disabled: _vm.flagDisabled,
                                        loading: loadingRequestCep,
                                        clearable: "",
                                        outlined: "",
                                      },
                                      model: {
                                        value: _vm.form.endereco.cep,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form.endereco,
                                            "cep",
                                            $$v
                                          )
                                        },
                                        expression: "form.endereco.cep",
                                      },
                                    }),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("base-text-field", {
                            directives: [
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: "AA",
                                expression: "'AA'",
                              },
                            ],
                            attrs: {
                              rules: "required",
                              id: "uf",
                              name: "UF",
                              disabled: true,
                              label: "UF *",
                              outlined: "",
                            },
                            model: {
                              value: _vm.form.endereco.estado,
                              callback: function ($$v) {
                                _vm.$set(_vm.form.endereco, "estado", $$v)
                              },
                              expression: "form.endereco.estado",
                            },
                          }),
                          _c("base-text-field", {
                            attrs: {
                              disabled: true,
                              rules: "required",
                              id: "municipio",
                              "data-test-id": "municipio",
                              name: "Município",
                              label: "Município *",
                              outlined: "",
                            },
                            model: {
                              value: _vm.form.endereco.cidade,
                              callback: function ($$v) {
                                _vm.$set(_vm.form.endereco, "cidade", $$v)
                              },
                              expression: "form.endereco.cidade",
                            },
                          }),
                          _c("base-text-field", {
                            attrs: {
                              rules: "required",
                              id: "bairro",
                              "data-test-id": "bairro",
                              name: "Bairro",
                              label: "Bairro *",
                              disabled: true,
                              clearable: "",
                              outlined: "",
                            },
                            model: {
                              value: _vm.form.endereco.bairro,
                              callback: function ($$v) {
                                _vm.$set(_vm.form.endereco, "bairro", $$v)
                              },
                              expression: "form.endereco.bairro",
                            },
                          }),
                          _c("base-text-field", {
                            attrs: {
                              rules: "required",
                              id: "logradouro",
                              "data-test-id": "logradouro",
                              name: "Logradouro",
                              label: "Logradouro *",
                              disabled: true,
                              clearable: "",
                              outlined: "",
                            },
                            model: {
                              value: _vm.form.endereco.logradouro,
                              callback: function ($$v) {
                                _vm.$set(_vm.form.endereco, "logradouro", $$v)
                              },
                              expression: "form.endereco.logradouro",
                            },
                          }),
                          _c("base-text-field", {
                            directives: [
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: ["############"],
                                expression: "['############']",
                              },
                            ],
                            attrs: {
                              rules: "required",
                              id: "numero",
                              "data-test-id": "numero",
                              name: "Número",
                              label: "Número *",
                              disabled: _vm.flagDisabled,
                              clearable: "",
                              outlined: "",
                            },
                            model: {
                              value: _vm.form.endereco.numero,
                              callback: function ($$v) {
                                _vm.$set(_vm.form.endereco, "numero", $$v)
                              },
                              expression: "form.endereco.numero",
                            },
                          }),
                          _c("base-text-field", {
                            attrs: {
                              rules: "",
                              id: "complemento",
                              name: "Complemento",
                              "data-test-id": "complemento",
                              label: "Complemento",
                              disabled: _vm.flagDisabled,
                              clearable: "",
                              outlined: "",
                            },
                            model: {
                              value: _vm.form.endereco.complemento,
                              callback: function ($$v) {
                                _vm.$set(_vm.form.endereco, "complemento", $$v)
                              },
                              expression: "form.endereco.complemento",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-container",
            {
              staticClass: "pt-0 mb-3",
              attrs: { "fill-height": "", fluid: "" },
            },
            [
              _c(
                "v-row",
                { attrs: { align: "center", justify: "center" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "4" } },
                    [
                      _vm.flagDisabled
                        ? _c(
                            "v-btn",
                            {
                              attrs: {
                                large: "",
                                elevation: "10",
                                block: "",
                                color: "warning",
                              },
                              on: {
                                click: function ($event) {
                                  _vm.flagDisabled = !_vm.flagDisabled
                                },
                              },
                            },
                            [_vm._v(" Editar ")]
                          )
                        : _c(
                            "v-btn",
                            {
                              attrs: {
                                large: "",
                                elevation: "10",
                                block: "",
                                color: "error",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.onClickCancelar()
                                },
                              },
                            },
                            [_vm._v(" Cancelar ")]
                          ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "4" } },
                    [
                      _vm.flagDisabled
                        ? _c(
                            "v-btn",
                            {
                              attrs: {
                                large: "",
                                elevation: "10",
                                block: "",
                                color: "primary",
                              },
                              on: { click: _vm.submit },
                            },
                            [_vm._v(" Confirmar Dados ")]
                          )
                        : _c(
                            "v-btn",
                            {
                              attrs: {
                                large: "",
                                elevation: "10",
                                block: "",
                                color: "primary",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.onClickSalvar()
                                },
                              },
                            },
                            [_vm._v(" Salvar ")]
                          ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "500" },
          model: {
            value: _vm.showModalConfirmacaoDados,
            callback: function ($$v) {
              _vm.showModalConfirmacaoDados = $$v
            },
            expression: "showModalConfirmacaoDados",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [_vm._v(" Confirmação de dados ")]),
              _vm.quantidadeAcessos >= 0
                ? _c("v-card-text", [
                    _vm._v(
                      " Olá " +
                        _vm._s(_vm.user.nome) +
                        ", é necessário confirmar seus dados cadastrais. Caso esta confirmação não seja realizada em até " +
                        _vm._s(_vm.quantidadeAcessos) +
                        " acesso(s) ao sistema, o sistema ficará indisponível até que a confirmação seja realizada. "
                    ),
                  ])
                : _c("v-card-text", [
                    _vm._v(
                      " Olá " +
                        _vm._s(_vm.user.nome) +
                        ", para acessar o sistema é necessário fazer a validação do seu e-mail e telefone. "
                    ),
                  ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "pt-1",
                      attrs: { color: "primary" },
                      on: {
                        click: function ($event) {
                          _vm.showModalConfirmacaoDados = false
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.quantidadeAcessos >= 0 ? "OK" : "VALIDAR"
                          ) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500" },
          model: {
            value: _vm.showModalAcessoBloqueado,
            callback: function ($$v) {
              _vm.showModalAcessoBloqueado = $$v
            },
            expression: "showModalAcessoBloqueado",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [_vm._v(" Aviso ")]),
              _c("v-card-text", [
                _vm._v(
                  " Olá " +
                    _vm._s(_vm.user.nome) +
                    ", para acessar o sistema é necessário fazer a validação do seu e-mail e telefone. "
                ),
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "pt-1",
                      attrs: { color: "error" },
                      on: { click: _vm.logoutApp },
                    },
                    [_vm._v(" CANCELAR ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "pt-1",
                      attrs: { color: "primary" },
                      on: { click: _vm.onClickValidar },
                    },
                    [_vm._v(" VALIDAR ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("validacao-token-celular", {
        ref: "refValidacaoTokenCelular",
        on: { "token-celular-valido": _vm.tokenCelularValido },
      }),
      _c("validacao-token-email", {
        ref: "refValidacaoTokenEmail",
        on: { "salvar-dados": _vm.salvarDados },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }